import { useEffect, useState } from 'react';
import fruitItems from './fruits.json';
import './App.css';

let lives = 7;
let winstreak = 0;
let ingame = false;
let win = false

function updateLivesDisplay(wrongGuess) { 

  const hearts = document.querySelector(".lives");
  
  if(hearts) {
    // effacer tous les coeurs affichés actuellement
    hearts.innerHTML = "";

    // ajouter un nombre d'images de coeurs correspondant à la valeur de 'lives'
    for (let i = 0; i < lives; i++) {
      const heartImage = document.createElement("img");
      heartImage.src = "heart.png";
      heartImage.classList.add("heart");
      
      if (wrongGuess) {
        const audio = document.getElementById("audio");
        audio.play();

        const heartImages = document.querySelectorAll(".heart");
        const numHearts = heartImages.length;
        const heartsToRemove = numHearts - lives;

        for (let i = 0; i < heartsToRemove; i++) {
          heartImages[i].classList.add("fade-out");
        }
      }

      hearts.appendChild(heartImage);
    }
  }
  
}

function changeImage() {
  var img = document.getElementById("sound_img");
  const audio = document.getElementById("musique_mc");  
  if (img.src.match("sound_off.png")) {
    img.src = "sound_on.png";
    img.style.height = "70px";
    img.style.marginBottom = "88px";
    audio.play();
  } else {
    img.src = "sound_off.png";
    img.style.height = "40px";
    img.style.marginBottom = "105px";
    audio.pause();
  }
  
}

function Card({ fruit, flipped, chooseCard }) {

  const cardClickHandle = (e) => {
   
    chooseCard(fruit)
  }
  
    return <div className={`card ${flipped ? 'matched' : ''}`} onClick={cardClickHandle} >
      <img style={{ transform: "rotateY(180deg)" }} src={fruit.src} alt="img"/>
      <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
        <path d="M8 8a3.5 3 0 0 1 3.5 -3h1a3.5 3 0 0 1 3.5 3a3 3 0 0 1 -2 3a3 4 0 0 0 -2 4"></path>
        <line x1="12" y1="19" x2="12" y2="19.01"></line>
      </svg>
    </div>
  
}

/*function WinScreen({ onClick }) {
  return (
    <div className="screen win-screen">
      <h2>Félicitations, vous avez gagné !</h2>
      <button onClick={onClick}>Rejouer</button>
    </div>
  );
}

function LoseScreen({ onClick }) {
  return (
    <div className="screen lose-screen">
      <h2>Dommage, vous avez perdu !</h2>
      <button onClick={onClick}>Réessayer</button>
    </div>
  );
}*/

function App() {

  updateLivesDisplay(false);

  const [fruits, setFruits] = useState([]);
  const [fruitOne, setFruitOne] = useState(null);
  const [fruitTwo, setFruitTwo] = useState(null);
  //const [gameState, setGameState] = useState('playing'); // 'playing', 'win', 'lose'

  const chooseCard = (fruit) => {
    const audio = document.getElementById("audio3");
    if (fruitOne && fruitOne.matched) {
      setFruitOne(fruit);
    } else if (fruitOne && !fruitOne.matched && !fruit.matched){ 
      setFruitTwo(fruit);
      audio.play();
   } else if(fruitOne && fruit.matched){
      //on fait rien
    } else{
      // Aucune carte n'est retournée, affecte fruit à fruitOne
      setFruitOne(fruit);
      audio.play();
    }
  }

  const initGame = () => {
    ingame = true;
    const allFruits = [...fruitItems, ...fruitItems]
      .sort(() => Math.random() - .5)
      .map((item) => ({ ...item, id: Math.random() }))
    setFruits(allFruits)
  }

  const soundReset = () => {
      const audio = document.getElementById("audio2");
      audio.play();
  }

  const soundWin = () => {
    win = true;
    winstreak +=1;
    const audio_win = document.getElementById("audio_win");
    audio_win.play();
  }

  const soundLose = () => {
    win = false;
    const audio_lose = document.getElementById("audio_lose");
    audio_lose.play();
  }

  const resetGame = () => {

    /*const resetImg = document.getElementById("reset");
    
    resetImg.classList.add("reset--is-resetting");*/
    lives = 7;
    if(!win) {
      winstreak = 0;
    }
    win=false;
    var imgWin = document.getElementById("arrow_img");
    imgWin.style.opacity="0";
    updateLivesDisplay(false);
    setFruits(prevFruits => {
      return prevFruits.map(item => {
        if (item.matched) {
          return { ...item, matched: false }
        }

        return item
      })
    })

    setFruitOne(null)
    setFruitTwo(null)

    //setGameState('playing'); // état de jeu

    setTimeout(() => {
      initGame()
    }, 500)
  }

  useEffect(() => {
    if(lives<1){
      soundLose();
      resetGame(); //a changer
    }

    const allMatched = fruits.every(item => item.matched);
    if (allMatched && ingame) { //win
      //setGameState('win');
      var imgWin = document.getElementById("arrow_img");
      imgWin.style.opacity="1";
      soundWin();
      //resetGame();
    }
   
    if (fruitOne && fruitTwo && fruitOne !== fruitTwo) {

      if (fruitOne.src === fruitTwo.src) {
        setFruits(prevFruits => {
          return prevFruits.map(item => {
            
           if (item.src === fruitOne.src) {
              return { ...item, matched: true}
            } else {
              return item
              
            }
          })
        })
      }

      else {
        lives -= 1;  //faire perdre un coeur
        setTimeout(() => {
          updateLivesDisplay(true);
        }, 100);
      }

      setTimeout(() => {
        setFruitOne(null)
        setFruitTwo(null)
      }, 500)
    }

    
  }, [fruitTwo, fruitOne])

  return <>
    <h1>Memory en bikini</h1>
    {
      fruits.length ? <>
        <button className='reset' onClick={() => { resetGame(); soundReset();}}>
          <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M19.933 13.041a8 8 0 1 1 -9.925 -8.788c3.899 -1.002 7.935 1.007 9.425 4.747"></path>
            <path d="M20 4v5h-5"></path>
          </svg>
        </button>

        <div className="lives">
          
          <img className="heart" src="heart.png"></img>
          <img className="heart" src="heart.png"></img>
          <img className="heart" src="heart.png"></img>
          <img className="heart" src="heart.png"></img>
          <img className="heart" src="heart.png"></img>
          <img className="heart" src="heart.png"></img>
          <img className="heart" src="heart.png"></img>
        </div>

        <div className='sound'>
          <img className="sound_img_base" src="volume.png" onClick={() => { changeImage();}}></img>
        </div>
        <div className='soundChange'>
          <img id = "sound_img" className="sound_img" src="sound_off.png"></img>
        </div>

        <div className="winStreak">
          {winstreak}
        </div>

        <div className='fire'>
          <img id = "fire_img" className="winstreak" src="fire.png"></img>
        </div>

        <div className='arrow'>
          <img id = "arrow_img" className="arrows" src="arrow.png"></img>
        </div>

        <div className="game-block">
          {
            fruits.map((fruit, key) => {
              return <Card
                key={key}
                chooseCard={chooseCard}
                flipped={fruit === fruitOne || fruit === fruitTwo || fruit.matched}
                fruit={fruit}
              />
            })
          }
        </div>
      </> : <button className='start-game' onClick={() => { initGame(); updateLivesDisplay(false);}}>
        Embuscadez-vous ?
      </button>
    }
  </>;
}

export default App;
